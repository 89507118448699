import React, { useState } from 'react';
import axiosInstance from './axiosInstance';
// import Header from './Header';
import Footer from './Footer';

const Search = () => {
  const [name, setName] = useState('');
  const [county, setCounty] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [city, setCity] = useState('');
  const [phone, setPhone] = useState('');
  const [classification, setClassification] = useState('');
  const [address, setAddress] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await axiosInstance.get('http://localhost:8080/api/v1/contractor/search', {
        params: { name, county, zipcode, city, phone, classification, address }
      });
      setResults(response.data);
    } catch (err) {
      setError(err.response ? err.response.data.message : err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* <Header /> */}
      <div className="min-h-screen bg-gray-100 flex items-center justify-center py-10">
        <div className="w-full max-w-2xl p-8 bg-white shadow-lg rounded-lg">
          <h2 className="text-3xl font-bold mb-6">Search for Contractors</h2>
          <form onSubmit={handleSearch} className="space-y-4">
            <div>
              <label className="block mb-1 font-semibold">Name</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
              />
            </div>
            <div>
              <label className="block mb-1 font-semibold">County</label>
              <input
                type="text"
                value={county}
                onChange={(e) => setCounty(e.target.value)}
                className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
              />
            </div>
            <div>
              <label className="block mb-1 font-semibold">Zipcode</label>
              <input
                type="text"
                value={zipcode}
                onChange={(e) => setZipcode(e.target.value)}
                className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
              />
            </div>
            <div>
              <label className="block mb-1 font-semibold">City</label>
              <input
                type="text"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
              />
            </div>
            <div>
              <label className="block mb-1 font-semibold">Phone</label>
              <input
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
              />
            </div>
            <div>
              <label className="block mb-1 font-semibold">Classification</label>
              <input
                type="text"
                value={classification}
                onChange={(e) => setClassification(e.target.value)}
                className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
              />
            </div>
            <div>
              <label className="block mb-1 font-semibold">Address</label>
              <input
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
              />
            </div>
            <button
              type="submit"
              disabled={loading}
              className="w-full p-2 bg-black hover:bg-blue-600 text-white rounded-md"
            >
              {loading ? 'Searching...' : 'Search'}
            </button>
            {error && <p className="text-red-500 mt-2">{error}</p>}
          </form>
          {results.length > 0 && (
            <div className="mt-6">
              <h3 className="text-xl font-semibold mb-2">Results</h3>
              <ul className="space-y-2">
                {results.map((contractor) => (
                  <li key={contractor.id} className="p-4 border rounded-md">
                    <p className="font-bold">{contractor.business_name}</p>
                    <p>{contractor.city}, {contractor.state}</p>
                    <p>{contractor.business_phone}</p>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Search;
