import React, { useState } from 'react';

const ContactSection = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [statusMessage, setStatusMessage] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setStatusMessage('Your message has been sent successfully!');
        setFormData({ name: '', email: '', message: '' });
      } else {
        setStatusMessage('Oops! Something went wrong. Please try again.');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setStatusMessage('Oops! Something went wrong. Please try again.');
    }
  };

  return (
    <section id="contact" className="py-16 bg-gray-200">
      <div className="container mx-auto px-6 lg:px-16">
        <h2 className="text-5xl font-bold text-center mb-12 text-gray-900">Get in Touch</h2>

        <div className="max-w-3xl mx-auto bg-white p-8 rounded-lg shadow-xl">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="mb-4">
              <label className="block text-gray-700 mb-2 font-medium">Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full p-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-500"
                placeholder="Your Name"
                aria-label="Your Name"
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 mb-2 font-medium">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-500"
                placeholder="you@example.com"
                aria-label="Your Email"
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 mb-2 font-medium">Message</label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="w-full p-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-500"
                placeholder="Your message here..."
                aria-label="Your Message"
                required
              ></textarea>
            </div>

            <button
              type="submit"
              className="w-full p-4 bg-gray-800 text-white rounded-lg hover:bg-gray-700 transition duration-300"
            >
              Send Message
            </button>

            {statusMessage && (
              <p className="mt-4 text-center text-lg text-gray-800">{statusMessage}</p>
            )}
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
