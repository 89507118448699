import React from 'react';

const ServicesSection = () => {
  return (
    <section id='services' className="py-16 bg-gradient-to-r from-gray-100 to-gray-200">
      <div className="container mx-auto text-center">
        <h2 className="text-5xl font-bold mb-12 text-gray-900">Services coming soon...</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          <div className="bg-white p-8 rounded-lg shadow-sm transition-transform duration-300 transform hover:scale-105">
            <h3 className="text-2xl font-semibold mb-4 text-gray-800">Design</h3>
            <p className="text-gray-600">Bringing your vision to life with expert design tailored to your needs.</p>
          </div>
          <div className="bg-white p-8 rounded-lg shadow-sm transition-transform duration-300 transform hover:scale-105">
            <h3 className="text-2xl font-semibold mb-4 text-gray-800">Estimating</h3>
            <p className="text-gray-600">Utilizing web tools to ensure accurate and efficient cost estimating to meet any project budget.</p>
          </div>
          <div className="bg-white p-8 rounded-lg shadow-sm transition-transform duration-300 transform hover:scale-105">
            <h3 className="text-2xl font-semibold mb-4 text-gray-800">Project Management</h3>
            <p className="text-gray-600">Managing every aspect of your project to ensure success and satisfaction through sophiscated software.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
