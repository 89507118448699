import React from 'react';
import backgroundImage from './images/pexels-rpnickson.jpg'

const HeroSection = () => {
  return (
    <section id='hero' className="relative h-screen bg-cover bg-center" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="relative z-10 flex flex-col justify-center items-center text-center text-white h-full bg-black bg-opacity-30">
        <h1 className="text-6xl font-bold mb-4">UrbanTectonic</h1>
        <p className="text-2xl font-light">Your Vision, Our Blueprint</p>
      </div>
    </section>
  );
};




export default HeroSection;
