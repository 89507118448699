import React from 'react';
import pexelsImage from './images/pexels-energepic.jpg';


const AboutSection = () => {
  return (
    <section id='about' className="py-20 bg-gray-100">
      <div className="container mx-auto flex flex-col md:flex-row items-center">
        <div className="md:w-1/2 px-6 bg-white bg-opacity-75 shadow-lg rounded-lg p-8">
          <h2 className="text-5xl font-bold text-gray-900 mb-6">About Us</h2>
          <p className="text-lg text-gray-700 leading-relaxed">
            At <span className="text-black font-bold">UrbanTectonic</span>, we specialize in transforming your home-building dreams into reality. Whether it's a remodel or a ground-up construction, we bring a team with a portfolio of over <span className="font-semibold text-gray-900">$300M</span> of building experience. We prioritize your vision, cost, and schedule - ensuring quality and excellence at every step of the journey.
          </p>
        </div>
        <div className="md:w-1/2 mt-10 md:mt-0 px-6">
          <img
            src={pexelsImage}
            alt="Urban Tectonic Construction"
            className="rounded-lg shadow-xl transition-transform duration-300 transform hover:scale-105"
          />
        </div>
      </div>
    </section>
  );
};


export default AboutSection;
